import React, { useState, useEffect } from "react"
import CasesTable from "./components/CasesTable/CasesTable"
import { updateCaseInfo } from "../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Button, Box, CircularProgress } from "@mui/material"
import Sidebar from "../../components/SideBar/Sidebar"
import NewAlert from "./components/NewAlert"
import EmptyBox from "./components/EmptyBox"
import plusIcon from "../../assets/icons/MainPage/plusIcon.svg"
import plusIconDisabled from "../../assets/icons/MainPage/plusIconDisabled.svg"
import { useOutletContext } from "react-router-dom"
import styles from "./MainPage.module.scss"
import CaseReportDialog from "./components/CaseReportDialog"

const urgencyOptions = ["Low", "Medium", "High"]

const ComplianceMainPage = () => {
    const [
        control,
        values,
        triggerFetchMainPageData,
        setTriggerFetchMainPageData,
        loadMainPageData,
        caseTypes,
        isInitialLoading,
    ] = useOutletContext()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)
    const [openNewAlertPopup, setOpenNewAlertPopup] = useState(false)
    const [welcomeState, setWelcomeState] = useState(false)
    const [sections, setSections] = useState([
        {
            cases: values?.in_progress || [],
            headerTitle: "In progress",
            emptyText: "No pending reports",
            controllerName: "in_progress",
            done: false,
        },
        {
            cases: values?.closed || [],
            headerTitle: "Done",
            emptyText: "No completed reports yet",
            controllerName: "done",
            done: true,
        },
    ])

    const [displayedReportId, setDisplayedReportId] = useState(null)
    const [caseReportDialogOpen, setCaseReportDialogOpen] = useState(false)
    const [auditTrailPreview, setAuditTrailPreview] = useState("<div/>")
    const [successEmailOpen, setSuccessEmailOpen] = useState(false)
    const [failEmailOpen, setFailEmailOpen] = useState(false)

    const openReportDialogById = (id) => {
        setDisplayedReportId(id)
        setCaseReportDialogOpen(true)
    }

    const getWelcomeSubText = () => {
        if (currentCompanyId === 3) {
            return (
                <>
                    Login to{" "}
                    <a href="https://login.salesforce.com/" target="_blank" rel="noreferrer">
                        Saleforce
                    </a>{" "}
                    and get your cases{" "}
                </>
            )
        } else {
            return "Create a new EDD request and get your report"
        }
    }

    const updateCaseInfoMap = async (case_id, type, value) => {
        await updateCaseInfo(case_id, type, value, dispatch, refreshAccessTokenCounter)
    }

    useEffect(() => {
        if (values?.in_progress && values?.done) {
            if (
                !isInitialLoading &&
                values?.in_progress.length === 0 &&
                values?.done.length === 0
            ) {
                setWelcomeState(true)
            } else {
                setWelcomeState(false)
            }
        }
        setSections([
            {
                ...sections[0],
                cases: values?.in_progress || [],
            },
            {
                ...sections[1],
                cases: values?.done || [],
            },
        ])
    }, [values?.new_cases, values?.in_progress, values?.closed, isInitialLoading])

    function getDoneItemById(values, id) {
        if (!values || !values.done || !Array.isArray(values.done)) {
            throw new Error("Invalid input: 'values.done' should be an array.")
        }

        return values.done.find((item) => item.id === id)?.completed_report || null
    }

    return (
        <Box className={styles.mainPageContainer}>
            {caseReportDialogOpen && (
                <CaseReportDialog
                    open={caseReportDialogOpen}
                    setClosed={() => setCaseReportDialogOpen(false)}
                    caseTitle={values.title}
                    auditTrailPreview={auditTrailPreview}
                    reportData={getDoneItemById(values, displayedReportId)}
                    control={control}
                    successEmailOpen={successEmailOpen}
                    setSuccessEmailOpen={setSuccessEmailOpen}
                    failEmailOpen={failEmailOpen}
                    setFailEmailOpen={setFailEmailOpen}
                    displayedReportId={displayedReportId}
                />
            )}
            <NewAlert
                openNewAlertPopup={openNewAlertPopup}
                setOpenNewAlertPopup={setOpenNewAlertPopup}
                triggerFetchMainPageData={triggerFetchMainPageData}
                setTriggerFetchMainPageData={setTriggerFetchMainPageData}
                updateCaseInfoMap={updateCaseInfoMap}
                loadMainPageData={loadMainPageData}
                caseTypes={caseTypes}
            />
            <Sidebar />
            <Box className={styles.mainPageHeaderWrapper}>
                <Typography fontSize="24px" color="#000">
                    My Due Diligence Reports
                </Typography>
                <Button
                    style={{ color: isInitialLoading ? "#e0e0e0" : "#fff" }}
                    disabled={isInitialLoading}
                    className={styles.mainPageHeaderButton}
                    onClick={() => setOpenNewAlertPopup(!openNewAlertPopup)}
                >
                    <img src={isInitialLoading ? plusIconDisabled : plusIcon}></img>New EDD Request
                </Button>
            </Box>
            {isInitialLoading ? (
                <Box className={styles.mainPageLoader}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {welcomeState && !isInitialLoading ? (
                        <EmptyBox
                            mainText={"Welcome to Redstrings!"}
                            subText={getWelcomeSubText()}
                        />
                    ) : (
                        <>
                            <Box className={styles.mainPageTableWrapper}>
                                {sections.map(
                                    (
                                        { cases, headerTitle, emptyText, controllerName, done },
                                        index,
                                    ) => (
                                        <React.Fragment key={`${headerTitle}${index}`}>
                                            {cases && cases.length > 0 ? (
                                                <>
                                                    <CasesTable
                                                        values={values}
                                                        triggerFetchMainPageData={
                                                            triggerFetchMainPageData
                                                        }
                                                        setTriggerFetchMainPageData={
                                                            setTriggerFetchMainPageData
                                                        }
                                                        control={control}
                                                        updateCaseInfoMap={updateCaseInfoMap}
                                                        urgencyOptions={urgencyOptions}
                                                        loadMainPageData={loadMainPageData}
                                                        cases={cases}
                                                        headerTitle={headerTitle}
                                                        controllerName={controllerName}
                                                        done={done}
                                                        openReportDialogById={openReportDialogById}
                                                    />
                                                </>
                                            ) : (
                                                <EmptyBox subText={emptyText} />
                                            )}
                                        </React.Fragment>
                                    ),
                                )}
                            </Box>
                        </>
                    )}
                </>
            )}
        </Box>
    )
}

export default ComplianceMainPage
