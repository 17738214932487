import React, { useEffect, useState, useMemo } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { styled } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import {
    Box,
    List,
    CssBaseline,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material"
import logOutLogo from "../../../../assets/icons/AsideMenu/logOut.svg"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { PopOver } from "../../../../components/PopOver/PopOver"
import { AsideMenuItem } from "./components/AsideMenuItem/AsideMenuItem"
import { complianceMainPageMenuItems } from "../../../../constants/appConstants"
import { isRedstringsUser } from "../../../../helpers/standardHelpers"
import styles from "./AsideMenu.module.scss"

const drawerWidth = 260

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: "ease",
        duration: "0.5s",
    }),
    color: "#9AA3AB",
    overflowX: "hidden",
    backgroundColor: "#000000",
    border: "none",
    top: "64px",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: "ease",
        duration: "0.5s",
    }),
    overflowX: "hidden",
    backgroundColor: "#000000",
    color: "#9AA3AB",
    border: "none",
    top: "64px",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
)

export function AsideMenu({ setSelectedMenuItem, selectedMenuItem, setOpenMenu, openMenu }) {
    const { user, logout } = useAuth0()
    const [anchorLogout, setAnchorLogout] = useState(null)

    const isAdmin = useSelector((state) => state.userState.user.admin)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    // TODO: Rewrite to normal user rules model
    const filteredMenuItems = useMemo(() => {
        if (isRedstringsUser(user)) {
            return complianceMainPageMenuItems(user)
        } else {
            return complianceMainPageMenuItems(user).filter((menuItem) => {
                if (currentCompanyId !== 2 && menuItem.id === 3) {
                    return false
                }
                if (isAdmin && menuItem.id === 4) {
                    return true
                }
                return !menuItem.admin
            })
        }
    }, [currentCompanyId, user.email, isAdmin])

    const handleDrawerOpen = () => {
        setOpenMenu(true)
    }

    const handleDrawerClose = () => {
        setOpenMenu(false)
    }

    const handleMenuItemClick = (itemId) => {
        setSelectedMenuItem(itemId)
    }

    return (
        <Box className={styles.asideMenuContainer}>
            <CssBaseline />
            <Drawer variant="permanent" open={openMenu}>
                {openMenu ? (
                    <ArrowBackIosNewIcon
                        onClick={handleDrawerClose}
                        className={styles.asideMenuArrow}
                    />
                ) : (
                    <ArrowForwardIosIcon
                        onClick={handleDrawerOpen}
                        className={styles.asideMenuArrow}
                    />
                )}
                <List className={styles.asideMenuListWrapper}>
                    {filteredMenuItems.map((menuItem) => (
                        <AsideMenuItem
                            key={menuItem.id}
                            openMenu={openMenu}
                            menuItem={menuItem}
                            selectedMenuItem={selectedMenuItem}
                            handleMenuItemClick={handleMenuItemClick}
                        />
                    ))}
                    <ListItem disablePadding className={styles.asideMenuListItem}>
                        <ListItemButton
                            onMouseEnter={(e) => setAnchorLogout(e.currentTarget)}
                            onMouseLeave={() => setAnchorLogout(null)}
                            onClick={() =>
                                logout({
                                    returnTo: window.location.origin,
                                })
                            }
                            className={styles.asideMenuListElement}
                        >
                            <ListItemIcon className={styles.asideMenuListIcon}>
                                <img width="20px" src={logOutLogo} alt="Logout" />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Logout"}
                                sx={{
                                    opacity: openMenu ? 1 : 0,
                                    transition: "opacity 0.5s ease",
                                }}
                            />
                            {!openMenu && (
                                <PopOver
                                    text={"Logout"}
                                    vertical="center"
                                    horizontal="right"
                                    anchorEl={anchorLogout}
                                    handlePopoverClose={() => setAnchorLogout(null)}
                                />
                            )}
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    )
}
