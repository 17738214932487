import React, { useState } from "react"
import { Typography, Box } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"

const TitleAndLinks = ({ title, links, templates }) => {
    const [isHoveringArray, setIsHoveringArray] = useState(new Array(links.length).fill(false))

    const handleMouseOver = (index) => {
        const newIsHoveringArray = [...isHoveringArray]
        newIsHoveringArray[index] = true
        setIsHoveringArray(newIsHoveringArray)
    }

    const handleMouseOut = () => {
        setIsHoveringArray(new Array(links.length).fill(false))
    }

    return (
        <div>
            <Typography
                variant="body1"
                fontSize="16px"
                color="rgba(45, 45, 45, 0.95)"
                marginBottom="10px"
                paddingLeft="40px"
                fontWeight={600}
                sx={{
                    textTransform: "capitalize",
                }}
            >
                {title}
            </Typography>
            <Box
                component={"ul"}
                sx={{
                    listStyleType: "none",
                    maxHeight: "300px",
                    overflow: "auto",
                }}
            >
                {links.map((link, index) => {
                    return (
                        <Box
                            key={`${link?.title}${index}`}
                            component="li"
                            // style={{ height: "21.95px" }}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={handleMouseOut}
                            sx={{
                                "&::before": {
                                    content: '"•"',
                                    color: "#D9D9D9",
                                    display: "inline-block",
                                    width: "1em",
                                    marginLeft: "-1em",
                                },
                            }}
                        >
                            <Typography
                                key={index}
                                component="a"
                                variant="body1"
                                color="#3E3E3E"
                                onClick={link.goto}
                                sx={{
                                    textDecoration: "underline",
                                    textTransform: "capitalize",
                                    display: "inline",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#5c5c5c",
                                    },
                                    transition: "color 0.1s",
                                }}
                            >
                                {link.title}
                            </Typography>
                            {/* {link.deleteTemplate && isHoveringArray[index] && ( */}
                            {link.deleteTemplate && (
                                <IconButton
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                        padding: "0 0 0 8px",
                                        transform: "scale(0.9)",
                                        ml: 1,
                                        "&.MuiButtonBase-root:hover": {
                                            bgcolor: "transparent",
                                        },
                                        visibility: isHoveringArray[index] ? "visible" : "hidden",
                                        // not sure its working properly
                                        transition: "visibility 0s linear 300ms, opacity 50ms",
                                    }}
                                    onClick={link.deleteTemplate}
                                    size="xs"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    )
                })}
            </Box>
            {links.length <= 0 && (
                <Typography
                    fontSize="14px"
                    color="#3E3E3E"
                    paddingLeft="40px"
                    sx={{
                        marginTop: "7px",
                    }}
                >
                    {`No ${title}`}
                </Typography>
            )}
            {templates && (
                <Typography
                    fontSize="11px"
                    color="rgba(45, 45, 45, 1)"
                    sx={{
                        textDecoration: "underline",
                        marginTop: "7px",
                        cursor: "pointer",
                    }}
                >
                    See all templates
                </Typography>
            )}
        </div>
    )
}

export default TitleAndLinks
