import React, { useEffect, useState } from "react"
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
} from "@mui/material"
import { useSelector } from "react-redux"
import { COMPLIANCE_MAIN_TABLE_CELLS } from "../../../../constants/appConstants"
import CaseTableRow from "./components/CaseTableRow/CaseTableRow"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import styles from "./CasesTable.module.scss"

const CasesTable = ({
    values,
    control,
    updateCaseInfoMap,
    cases,
    headerTitle,
    controllerName,
    loadMainPageData,
    openReportDialogById,
}) => {
    const [cells, setCells] = useState(COMPLIANCE_MAIN_TABLE_CELLS)
    const [currentPage, setCurrentPage] = useState(1)
    const [showIcons, setShowIcons] = useState({})

    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    const itemsPerPage = 5
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const handleMouseEnter = (index) => {
        setShowIcons({ ...showIcons, [index]: true })
    }

    const handleMouseLeave = (index) => {
        setShowIcons({ ...showIcons, [index]: false })
    }

    return (
        <Box className={styles.CasesTableContainer}>
            <Box className={styles.CasesTableHeader}>
                <Typography className={styles.CasesTableHeaderStatus}>{headerTitle}</Typography>
                <Typography className={styles.CasesTableHeaderQuantity}>
                    {cases?.length} {cases?.length === 1 ? "Report" : "Reports"}
                </Typography>
            </Box>
            <TableContainer className={styles.CasesTableMainContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {cells.map((cell, index) => (
                                <TableCell
                                    key={`${cell?.name}${index}`}
                                    align={cell?.align || "center"} // Default to "center" alignment
                                    style={{
                                        width: cell?.width || "auto", // Use specified width or auto
                                        minWidth: cell?.minWidth || "100px", // Ensure a minimum width
                                        maxWidth: cell?.maxWidth || "300px", // Prevent overflow
                                    }}
                                    className={styles.CasesTableMainTitleCell}
                                >
                                    <Box
                                        className={styles.CasesTableHeaderBox}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={
                                            cell?.align === "right" ? "flex-end" : "flex-start"
                                        }
                                        style={{ gap: "5px" }} // Add spacing between text and icons
                                    >
                                        <Typography
                                            fontSize="16px"
                                            fontWeight="500"
                                            style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {cell?.name}
                                        </Typography>
                                        {showIcons[index] && currentCompanyId === 5 && (
                                            <>
                                                <ArrowUpwardIcon
                                                    sx={{ width: "15px", height: "15px" }}
                                                />
                                                <MoreVertIcon
                                                    sx={{ width: "15px", height: "15px" }}
                                                />
                                            </>
                                        )}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cases?.slice(startIndex, endIndex)?.map((row, index) => (
                            <CaseTableRow
                                key={row?.id}
                                row={row}
                                updateCaseInfoMap={updateCaseInfoMap}
                                cases={cases}
                                loadMainPageData={loadMainPageData}
                                controllerName={controllerName}
                                openReportDialogById={openReportDialogById}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box marginTop="10px">
                <Pagination
                    count={Math.ceil(cases?.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                />
            </Box>
        </Box>
    )
}

export default CasesTable
