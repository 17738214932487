import React, { useMemo } from "react"
import { Page, Text, View, Document, StyleSheet, Font, Link } from "@react-pdf/renderer"
import Arial from "../../../../assets/fonts/Arial Unicode MS.ttf"

Font.register({
    family: "Arial",
    fonts: [{ src: Arial }],
})

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        width: "100%",
        fontFamily: "Arial",
        fontSize: 10,
        color: "#000",
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 10,
        marginTop: 30,
        marginLeft: 30,
        marginRight: 30,
    },
    sectionLine: {
        marginVertical: 5,
        height: 2,
        backgroundColor: "#f7f8fa",
        marginHorizontal: 30,
    },
    headerFields: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
        marginBottom: 20,
        marginHorizontal: 30,
    },
    headerFieldBox: {
        flexDirection: "row",
        alignItems: "center",
        marginRight: 20,
    },
    headerLabel: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: 12,
        marginRight: 5,
    },
    headerValue: {
        fontFamily: "Arial",
        fontWeight: "normal",
        fontSize: 12,
    },
    resolutionButton: {
        backgroundColor: "#c1bbf9",
        borderRadius: 4,
        padding: 4,
        width: 100,
        textAlign: "center",
        marginBottom: 8,
        fontSize: 10,
        fontWeight: "bold",
        color: "#5e49fd",
        marginHorizontal: 30,
    },
    resolutionButton1: {
        backgroundColor: "#c1bbf9",
        borderRadius: 4,
        padding: 4,
        width: 100,
        textAlign: "center",
        fontSize: 10,
        fontWeight: "bold",
        color: "#5e49fd",
        marginHorizontal: 30,
    },
    riskBox: {
        backgroundColor: "#f7f8fa",
        borderRadius: 4,
        paddingHorizontal: 10,
        paddingVertical: 4,
        fontSize: 10,
        fontWeight: "bold",
        textAlign: "center",
    },
    synopsisBox: {
        backgroundColor: "#f7f8fa",
        borderRadius: 8,
        padding: 10,
        marginBottom: 10,
        marginHorizontal: 30,
    },
    synopsisTitle: {
        fontSize: 11,
        fontWeight: "bold",
        color: "#5e49fd",
        marginBottom: 5,
    },
    recommendationBox: {
        backgroundColor: "#f7f8fa",
        borderRadius: 8,
        padding: 10,
        marginBottom: 10,
        marginHorizontal: 30,
    },
    redFlagsBox: {
        backgroundColor: "#faeced",
        borderRadius: 8,
        padding: 10,
        marginBottom: 20,
        marginHorizontal: 30,
    },
    reportSummaryBox: {
        border: "2px solid #f7f8fa",
        borderRadius: 8,
        padding: 10,
        marginBottom: 20,
        position: "relative",
        marginHorizontal: 30,
    },
    reportSummaryBox1: {
        marginBottom: 20,
        position: "relative",
    },
    reportSummaryHeader: {
        backgroundColor: "#c1bbf9",
        borderRadius: 8,
        paddingVertical: 4,
        paddingHorizontal: 10,
        fontSize: 11,
        fontWeight: "bold",
        color: "#5e49fd",
        marginBottom: 5,
        width: "50px",
    },
    checkRiskRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: 10,
        marginBottom: 3,
        borderTopWidth: 0,
        paddingTop: 2,
    },
    detailedReportBox: {
        backgroundColor: "#f7f8fa",
        borderRadius: 8,
        padding: 10,
        marginHorizontal: 30,
        marginBottom: 30,
    },
})

function processData(reportData) {
    const structuredData = {}
    let headerName = ""
    let headerDate = ""
    let resolution = ""
    let riskLevel = ""
    let companySummary = ""
    let recommendation = ""
    let redFlagsSummary = ""
    let summaryCheckRisks = {}

    reportData.forEach((item) => {
        const section = item.Section || ""
        const check = item.Check || ""
        const datapoint = item["Data Point"] || ""
        const result = item.Result || ""
        const redFlag = item["Red Flags"] || null

        if (section === "Summary" && check === "Summary" && datapoint === "Date Reviewed") {
            headerDate = result
        }
        if (
            section === "Company Verification" &&
            check === "Company Information" &&
            datapoint === "Trading Name"
        ) {
            headerName = result
        }

        if (section === "Summary" && check === "Summary") {
            if (datapoint === "Resolution") resolution = result
            if (datapoint === "Risk Level") riskLevel = result.trim()
            if (datapoint === "Company Summary") companySummary = result
            if (datapoint === "Recommendation") recommendation = result
            if (datapoint === "Red Flags") redFlagsSummary = result
            if (datapoint === "Company Verification")
                summaryCheckRisks["Company Verification"] = result.trim()
            if (datapoint === "Corporate Structure")
                summaryCheckRisks["Corporate Structure"] = result.trim()
        }

        if (!structuredData[section]) structuredData[section] = {}
        if (!structuredData[section][check]) structuredData[section][check] = {}

        let isRiskDP = datapoint.toLowerCase().includes("risk level")
        structuredData[section][check][datapoint] = {
            result: result,
            redFlag: redFlag,
            risk: isRiskDP ? result.trim() : null,
        }
    })

    return {
        structuredData,
        headerName,
        headerDate,
        resolution,
        riskLevel,
        companySummary,
        recommendation,
        redFlagsSummary,
        summaryCheckRisks,
    }
}

function getCheckRiskLevels(structuredData, sectionFilter) {
    const risks = {}
    if (!structuredData[sectionFilter]) return risks
    Object.keys(structuredData[sectionFilter]).forEach((checkName) => {
        let checkRisk = null
        Object.keys(structuredData[sectionFilter][checkName]).forEach((dp) => {
            if (dp.toLowerCase().includes("risk level")) {
                checkRisk = structuredData[sectionFilter][checkName][dp].result.trim()
            }
        })
        if (checkRisk) {
            risks[checkName] = checkRisk
        }
    })
    return risks
}

function colorForRisk(risk) {
    const r = (risk || "").toLowerCase()
    if (r.includes("low")) return "#4fbb7c"
    if (r.includes("medium")) return "#ffae4f"
    if (r.includes("high")) return "#e02b38"
    if (r.includes("inconclusive")) return "#6b869a"
    return "#000"
}

// New helper function to classify and color-code resolution
function classifyResolution(resolutionText) {
    const text = (resolutionText || "").toLowerCase()
    if (text.includes("positive")) {
        return { label: "Positive", color: "#4fbb7c" }
    } else if (text.includes("inconclusive")) {
        return { label: "Inconclusive", color: "#6b869a" }
    } else if (text.includes("negative")) {
        return { label: "Negative", color: "#e02b38" }
    } else {
        // Default case if none of the keywords found
        return { label: "Unknown", color: "#000" }
    }
}

// Parse red flags text into an array of bullets
function parseRedFlags(text) {
    if (!text || text.trim() === "") return []

    // We'll split the text into lines and parse bullets:
    // A bullet starts with a line that ends with ":" and the following lines (until next such line) belong to it.
    const lines = text.split(/\r?\n/)
    const bullets = []
    let currentTitle = null
    let currentContent = []

    lines.forEach((line) => {
        // Check if line ends with a colon (indicating a new bullet title)
        // We'll trim trailing spaces and see if it ends with ':'
        const trimmed = line.trim()
        if (trimmed.endsWith(":")) {
            // If there's a current bullet in progress, push it
            if (currentTitle !== null) {
                bullets.push({ title: currentTitle, content: currentContent.join(" ") })
            }
            // Start a new bullet
            currentTitle = trimmed.replace(/:$/, "") // remove the trailing colon
            currentContent = []
        } else {
            // It's content line
            // Add it to current content if there's a current bullet
            if (currentTitle !== null) {
                currentContent.push(line)
            }
        }
    })

    // Push the last bullet if any
    if (currentTitle !== null) {
        bullets.push({ title: currentTitle, content: currentContent.join(" ") })
    }

    return bullets
}

export function CaseReport({ reportData }) {
    const {
        structuredData,
        headerName,
        headerDate,
        resolution,
        riskLevel,
        companySummary,
        recommendation,
        redFlagsSummary,
        summaryCheckRisks,
    } = useMemo(() => processData(reportData), [reportData])

    const companyVerifRisks = getCheckRiskLevels(structuredData, "Company Verification")
    const corpStructRisks = getCheckRiskLevels(structuredData, "Corporate Structure")

    const { label: resolutionLabel, color: resolutionColor } = classifyResolution(resolution)

    function renderRiskSummary(sectionName, sectionRisk, checksRisks) {
        const filteredChecks = Object.keys(checksRisks).filter(
            (ch) => ch.toLowerCase() !== sectionName.toLowerCase(),
        )

        return (
            <View style={{ marginBottom: 10 }}>
                <View style={styles.checkRiskRow}>
                    <Text style={{ fontWeight: "bold" }}>{sectionName}</Text>
                    <Text style={[styles.riskBox, { color: colorForRisk(sectionRisk) }]}>
                        {sectionRisk}
                    </Text>
                </View>
                {filteredChecks.length > 0 && <Text style={{ marginVertical: 3 }}></Text>}
                {filteredChecks.map((ch) => (
                    <View style={[styles.checkRiskRow, { marginLeft: 20 }]} key={ch}>
                        <Text>{ch.toLowerCase()}</Text>
                        <Text style={[styles.riskBox, { color: colorForRisk(checksRisks[ch]) }]}>
                            {checksRisks[ch]}
                        </Text>
                    </View>
                ))}
            </View>
        )
    }

    function renderRedFlags(text) {
        const bullets = parseRedFlags(text)
        if (bullets.length === 0) return null

        return (
            <View style={styles.redFlagsBox}>
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 5,
                    }}
                >
                    <View
                        style={{
                            width: 10,
                            height: 10,
                            backgroundColor: "#e02b38",
                            marginRight: 5,
                        }}
                    />
                    <Text
                        style={{
                            fontSize: 11,
                            fontWeight: "bold",
                            color: "#e02b38",
                        }}
                    >
                        Red Flags
                    </Text>
                </View>
                {bullets.map((bullet, index) => (
                    <View
                        key={index}
                        style={{ marginBottom: 8, flexDirection: "row", alignItems: "flex-start" }}
                    >
                        <Text style={{ marginRight: 5, fontSize: 10 }}>•</Text>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                                {bullet.title}:
                            </Text>
                            <Text style={{ fontSize: 10, marginLeft: 10, marginTop: 2 }}>
                                {bullet.content.trim()}
                            </Text>
                        </View>
                    </View>
                ))}
            </View>
        )
    }

    function renderDetailedReport() {
        const sectionsToInclude = ["Company Verification", "Corporate Structure"]
        const includedData = {}
        sectionsToInclude.forEach((sec) => {
            if (structuredData[sec]) includedData[sec] = structuredData[sec]
        })

        return (
            <View style={styles.detailedReportBox}>
                {Object.keys(includedData).map((sectionName) => {
                    let sectionRisk = ""
                    if (summaryCheckRisks[sectionName]) {
                        sectionRisk = summaryCheckRisks[sectionName]
                    }

                    // Filter checks to remove those that match the sectionName
                    const filteredChecks = Object.keys(includedData[sectionName]).filter(
                        (checkName) => checkName.toLowerCase() !== sectionName.toLowerCase(),
                    )

                    return (
                        <View key={sectionName} style={{ marginBottom: 10 }}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: 3 }}>
                                {sectionName}:{" "}
                                <Text style={{ color: colorForRisk(sectionRisk) }}>
                                    {sectionRisk}
                                </Text>
                            </Text>
                            {filteredChecks.map((checkName) => {
                                let checkRisk = ""
                                let checkRedFlag = null
                                Object.keys(includedData[sectionName][checkName]).forEach((dp) => {
                                    if (dp.toLowerCase().includes("risk level")) {
                                        checkRisk =
                                            includedData[sectionName][checkName][dp].result.trim()
                                        if (includedData[sectionName][checkName][dp].redFlag) {
                                            checkRedFlag =
                                                includedData[sectionName][checkName][dp].redFlag
                                        }
                                    }
                                })

                                return (
                                    <View
                                        key={checkName}
                                        style={{ marginLeft: 10, marginBottom: 5 }}
                                    >
                                        <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                                            {checkName}:{" "}
                                            <Text style={{ color: colorForRisk(checkRisk) }}>
                                                {checkRisk}
                                            </Text>
                                            {checkRedFlag ? (
                                                <Text
                                                    style={{
                                                        fontSize: 10,
                                                        color: "#e02b38",
                                                        marginLeft: 5,
                                                    }}
                                                >
                                                    ({checkRedFlag})
                                                </Text>
                                            ) : null}
                                        </Text>
                                        {Object.keys(includedData[sectionName][checkName]).map(
                                            (dp) => {
                                                if (dp.toLowerCase().includes("risk level"))
                                                    return null
                                                const { result, redFlag } =
                                                    includedData[sectionName][checkName][dp]
                                                if (!result || result.toString().trim() === "")
                                                    return null
                                                return (
                                                    <View
                                                        style={{
                                                            flexDirection: "row",
                                                            marginBottom: 2,
                                                            marginLeft: 10,
                                                        }}
                                                        key={dp}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: 10,
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {dp
                                                                .replace(`${sectionName} `, "")
                                                                .replace(`${checkName} `, "")}
                                                            :
                                                        </Text>
                                                        <Text
                                                            style={{ fontSize: 10, marginLeft: 5 }}
                                                        >
                                                            {result.startsWith("http") ? (
                                                                <Link
                                                                    src={result}
                                                                    style={{
                                                                        fontSize: 10,
                                                                        textDecoration: "underline",
                                                                    }}
                                                                >
                                                                    {result}
                                                                </Link>
                                                            ) : (
                                                                result
                                                            )}
                                                        </Text>
                                                        {redFlag ? (
                                                            <Text
                                                                style={{
                                                                    fontSize: 10,
                                                                    color: "#e02b38",
                                                                    marginLeft: 5,
                                                                }}
                                                            >
                                                                ({redFlag})
                                                            </Text>
                                                        ) : null}
                                                    </View>
                                                )
                                            },
                                        )}
                                    </View>
                                )
                            })}
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <Document>
            <Page size={[595.28, 2000]} style={styles.page} wrap={false}>
                <View>
                    {/* Title */}
                    <Text style={styles.title}>ENHANCED DUE DILIGENCE (EDD)</Text>
                    <View style={styles.sectionLine} />

                    {/* Header fields: Name / Date */}
                    <View style={styles.headerFields}>
                        <View style={styles.headerFieldBox}>
                            <Text style={styles.headerLabel}>Name:</Text>
                            <Text style={styles.headerValue}>{headerName}</Text>
                        </View>
                        <View style={styles.headerFieldBox}>
                            <Text style={styles.headerLabel}>Date:</Text>
                            <Text style={styles.headerValue}>{headerDate}</Text>
                        </View>
                    </View>

                    {/* Resolution */}
                    <Text style={styles.resolutionButton}>Resolution</Text>

                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 10,
                            marginHorizontal: 30,
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: "#f7f8fa",
                                borderRadius: 8,
                                paddingHorizontal: 10,
                                paddingVertical: 6,
                                marginRight: 10,
                            }}
                        >
                            <Text style={{ fontSize: 10, lineHeight: 1.3, color: resolutionColor }}>
                                {resolutionLabel}: {resolution}
                            </Text>
                        </View>
                        <View
                            style={{
                                backgroundColor: "#f7f8fa",
                                borderRadius: 8,
                                paddingHorizontal: 10,
                                paddingVertical: 6,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    color: colorForRisk(riskLevel),
                                }}
                            >
                                {riskLevel}
                            </Text>
                        </View>
                    </View>

                    {/* Company Synopsis */}
                    {companySummary ? (
                        <View style={styles.recommendationBox}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 5,
                                }}
                            >
                                <View
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: "#5e49fd",
                                        marginRight: 5,
                                    }}
                                />
                                <Text
                                    style={{
                                        fontSize: 11,
                                        fontWeight: "bold",
                                        color: "#5e49fd",
                                    }}
                                >
                                    Company Synopsis
                                </Text>
                            </View>
                            <Text style={{ fontSize: 10, lineHeight: 1.3 }}>{companySummary}</Text>
                        </View>
                    ) : null}

                    {/* Recommendation */}
                    {recommendation ? (
                        <View style={styles.recommendationBox}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 5,
                                }}
                            >
                                <View
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: "#5e49fd",
                                        marginRight: 5,
                                    }}
                                />
                                <Text
                                    style={{
                                        fontSize: 11,
                                        fontWeight: "bold",
                                        color: "#5e49fd",
                                    }}
                                >
                                    Recommendation
                                </Text>
                            </View>
                            <Text style={{ fontSize: 10, lineHeight: 1.3 }}>{recommendation}</Text>
                        </View>
                    ) : null}

                    {/* Report Summary */}
                    <View style={{ marginTop: 20, marginBottom: 30 }}>
                        <Text style={styles.resolutionButton}>Report Summary</Text>
                        <View style={styles.reportSummaryBox}>
                            {renderRiskSummary(
                                "Company Verification",
                                summaryCheckRisks["Company Verification"] || "",
                                companyVerifRisks,
                            )}
                            {renderRiskSummary(
                                "Corporate Structure",
                                summaryCheckRisks["Corporate Structure"] || "",
                                corpStructRisks,
                            )}
                        </View>
                    </View>

                    {/* Red Flags */}
                    {renderRedFlags(redFlagsSummary)}

                    <View style={{ marginTop: 20, marginBottom: 30 }}>
                        <Text style={styles.resolutionButton}>Detailed Report</Text>
                        <View style={styles.reportSummaryBox1}>
                            {/* Detailed Report */}
                            {renderDetailedReport()}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
