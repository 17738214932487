const ROUTES = {
    TERMS: "/terms",
    PRIVACY: "/privacy",
    ROC: "/roc",
    REDIRECT: "/redirect",
    UNAUTHORIZED: "/unauthorized",
    OAUTH_CALLBACK: "/oauth/callback",
    CASE_ID: "/:caseId",
    TOOLBOX: "/:caseId/step/:stepId/toolbox",
    STEP_ID: "step/:stepId",
    ALL_INTEGRATIONS: "all/integrations",
    EASY_INTEGRATIONS: "easy/integrations",
    INTERNAL_CONFIGS: "internal/configs",
    ADMIN_PANEL: "admin-panel",
    PLAYBOOK: "home",
    PLAYBOOK_ID: "playbook/:templateId",
    MY_PLAYBOOKS: "my-playbooks",
    MY_PLAYBOOKS_ID: "my-playbooks/:templateId",
    // PLAYBOOK_REPO: "playbook/repo",
    // PLAYBOOK_REPO_ID: "playbook/repo/:templateId",
    DASHBOARD: "dashboard",
    MAIN_TOOLBOX: "/toolbox",
}

const API_ROUTES = {
    REGISTER_USER: "/register_user",
    SUBSCRIBE_USER: "/subscribe_user",
    CREATE_CASE: "/create_case",
    CREATE_EDD_REQUEST: "/create_edd_request",
    UPDATE_EDD_REQUEST: "/update_edd_request",
    UPDATE_CASE_INFO: "/update_case_info",
    ADD_TOOLS: "/add_tools",
    DELETE_TOOL_ACTION: "/delete_tool_action",
    EXPORT_AUDIT_TRAIL: "/export_audit_trail",
    SEND_PDF_REPORT: "/send_pdf_report",
    DO_TOOL_ACTION: "/do_tool_action",
    GET_TOOL_ACTION_LOG: "/get_tool_action_log",
    GET_MIDDESK_BUSINESSES: "/get_middesk_businesses",
    GET_TOOLS_CONFIG: "/get_tools_config",
    GET_PLAYBOOK_TOOLS_CONFIG: "/get_playbook_tools_config",
    GET_TOOLS_BY_ID: "/get_tools_by_id",
    GET_CASE_DATA: "/get_case_data",
    GET_TEMPLATE_DATA: "/get_template_data",
    GET_MAIN_PAGE_DATA: "/get_main_page_data",
    GET_COMPLIANCE_MAIN_PAGE_DATA: "/get_compliance_main_page_data",
    LAST_X_DAYS_CASE_STATUSES: "/last_x_days_case_statuses",
    DELETE_CASE: "/delete_case",
    GET_ENTITY_RELATED_CASES: "/get_entity_related_cases",
    CONNECTED_INTEGRATIONS: "/connected_integrations",
    DELETE_INTEGRATION: "/delete_integration",
    GET_REPORT_PDF_LINK: "/get_report_pdf_link",
    GENERATE_PDF: "/generate-pdf",
    GET_USER_ID_BY_EMAIL: "/get_user_id_by_email",
    SET_CREDENTIALS_CONNECTION: "/set_credentials_connection",
    SAVED_MENU_CONFIGS: "/saved_menu_configs",
    SAVED_MENU_CONFIGS_BY_USER_COMPANY: "/saved_menu_configs_by_user_company",
    GET_CASE_TYPES_WITH_FIELDS: "/get_case_types_with_fields",
    GET_CASE_TYPES: "/get_case_types",
    GET_COMPANIES: "/get_companies",
    GET_USERS: "/get_users",
    UPDATE_USER_DELETED_STATUS: "/update_user_deleted_status",
    SEND_USER_INVITATION_MAIL: "/send_user_invitation_mail",
    UPDATE_USER_COMPANY: "/update_user_company",
    GET_ENTITIES_TYPES: "/get_entities_types",
    RUN_TEMPLATE: "/template/run",
    TEMPLATE: "/template",
    SAVE_TEMPLATE_FROM_CASE: "/template/save-from-case",
    GET_WORK_ITEM_DEPENDENCIES: "/get_work_item_dependencies",
    GET_PRE_SIGNED_URL_TO_UPLOAD_FILE: "/get_pre_signed_url_to_upload_file",
    GET_PRE_SIGNED_URL_TO_UPLOAD_FILE_JSON: "/get_pre_signed_url_to_upload_file_json",
    CREATE_AND_UPLOAD_FILE: "/create_and_upload_file",
    USER_FEEDBACK_ACTION: "/user_feedback_action",
    GET_FEEDBACK_ACTION: "/get_feedback_action",
    GET_APPROVED_PLAYBOOKS: "/get_approved_playbooks",
    GET_USER_PLAYBOOKS: "/get_user_playbooks",
    COPY_PLAYBOOK_TO_USER: "/copy_playbook_to_user",
    CREATE_EMPTY_PLAYBOOK: "create_empty_playbook",
    DELETE_USER_PLAYBOOK: "/delete_user_playbook",
    CONTACT_US_PLAYBOOK: "/contact_us_playbook",
    UPDATE_LOG: "/update_log",
    RUNNER: "/runner",
    GET_RUNNERS: "/runner/list",
    CREATE_RUNNER: "/runner/create",
    RESET_DEMO_USER: "/demo/user/reset",
    SHARE_PLAYBOOK_WITH_EMAIL_INVITES: "/share_playbook_with_email_invites",
}

const ENUMS = {
    ROUTES,
    API_ROUTES,
}

export default ENUMS
