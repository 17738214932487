import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const getMainPageData = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_MAIN_PAGE_DATA, {})
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getComplianceMainPageData = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_COMPLIANCE_MAIN_PAGE_DATA, {})
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createCase = async (toolbox, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CREATE_CASE, { toolbox })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createEDDRequest = async (values, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CREATE_EDD_REQUEST, values)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const updateEDDRequest = async (values, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.UPDATE_EDD_REQUEST, values)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const deleteCase = async (case_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.DELETE_CASE, {
            case_id,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const runTemplate = async (values, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.RUN_TEMPLATE, values)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const lastXDaysCaseStatuses = async (last_x_days, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.LAST_X_DAYS_CASE_STATUSES, {
            last_x_days,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}
