import React, { useState, useRef, useEffect } from "react"
import { Box, Button, Tooltip, TextField, CircularProgress } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Steps } from "./components/Steps/Steps"
import { useParams, Link } from "react-router-dom"
import { useIsMobile } from "../../../../../../../../hooks/standart/useIsMobile"
import { SubscribeDialog } from "./components/SubscribeDialog/SubscribeDialog"
import { Controller } from "react-hook-form"
import dislikeIcon from "../../../../../../../../assets/icons/Repo/dislikeIcon.svg"
import dislikeIconActive from "../../../../../../../../assets/icons/Repo/dislikeIconActive.svg"
import likeIcon from "../../../../../../../../assets/icons/Repo/likeIcon.svg"
import likeIconActive from "../../../../../../../../assets/icons/Repo/likeIconActive.svg"
import successIcon from "../../../../../../../../assets/icons/successIcon.svg"
import buttonSaveChanges from "../../../../../../../../assets/icons/Repo/buttonSaveChanges.svg"
import { CustomPopOver } from "../../../../../../../../components/CustomPopOver/CustomPopOver"
import { useAuth0 } from "@auth0/auth0-react"
import { postUserFeedbackAction } from "../../../../../../../../services/playbook/playbookServices"
import { USER_REPO_FEEDBACK } from "../../../../../../../../constants/appConstants"
import { DesignerInfo } from "./components/DesignerInfo/DesignerInfo"
import { getCookie } from "../../../../../../../../helpers/cookieHelpers"
import { useRepoTemplateData } from "../../../../../../../../hooks/repo/useRepoTemplateData"
import { LoadingButton } from "@mui/lab"
import { CategoryInfo } from "./components/CategoryInfo/CategoryInfo"
import { getCaseTypes } from "../../../../../../../../services/standard/standardServices"
import styles from "./TemplateInfo.module.scss"

export function TemplateInfo({
    templateData,
    setValue,
    control,
    selectedStepData,
    handleSelectStep,
    selectedStepIndex,
    toolsDim,
    playbook,
    defaultLikes,
    defaultDislikes,
    reset,
    handleSubmit,
    errors,
    setEditMode,
    editMode,
    isAllowToEdit,
    setToolsIdArray,
    toolsIdArray,
    toolsConfig,
    fetchUserPlaybooks,
}) {
    const [showSubscribeDialog, setShowSubscribeDialog] = useState(false)
    const [isLiked, setIsLiked] = useState(false)
    const [isDisliked, setIsDisliked] = useState(false)
    const [isOverflowingDescription, setIsOverflowingDescription] = useState(false)
    const [isProcessingFeedback, setIsProcessingFeedback] = useState(false)
    const [isPopoverVisible, setPopoverVisible] = useState(false)
    const [likes, setLikes] = useState(defaultLikes || [])
    const [dislikes, setDislikes] = useState(defaultDislikes || [])
    const [caseTypes, setCaseTypes] = useState([])

    const [editName, setEditName] = useState(false)
    const [editDescription, setEditDescription] = useState(false)
    const [editCategory, setEditCategory] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const userId = useSelector((state) => state.userState.user.id)

    const session_id = getCookie("session_id")
    const userIdentifier = userId || session_id

    const { name, linkedin_profile, displayName, description, tools } = templateData
    const [, , templateDataLoading, handleUpdateTemplateData] = useRepoTemplateData()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { templateId } = useParams()

    const dialogRef = useRef(null)
    const buttonRef = useRef(null)
    const nameInputRef = useRef(null)
    const descriptionInputRef = useRef(null)

    const { user } = useAuth0()

    const templateInfoContainerClass = isMobile
        ? styles.templateInfoContainerMobile
        : styles.templateInfoContainer

    const fetchCaseTypes = async () => {
        try {
            const caseTypesResults = await getCaseTypes(dispatch, refreshAccessTokenCounter)
            if (caseTypesResults?.length > 0) {
                setCaseTypes(caseTypesResults)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePostUserFeedbackAction = async (data) => {
        try {
            if (!userIdentifier) {
                console.error("No user identifier (user or session_id) available.")
                return
            }

            const feedbacks = await postUserFeedbackAction(
                { ...data, object_id: templateId, user_id: userIdentifier },
                dispatch,
                refreshAccessTokenCounter,
            )
            setLikes(feedbacks.filter((item) => item.decision === 1) || [])
            setDislikes(feedbacks.filter((item) => item.decision === 2) || [])
        } catch (error) {
            console.log(error)
        }
    }

    const handleEditName = () => {
        setEditName(true)
    }

    const handleEditDescription = () => {
        setEditDescription(true)
    }

    const handleEditCategory = () => {
        setEditCategory(true)
    }

    const handleShareClick = (event) => {
        navigator.clipboard
            .writeText(`https://www.app.redstrings.io/playbook/${templateId}`)
            .then(() => {
                setPopoverVisible(true)
                setTimeout(() => {
                    setPopoverVisible(false)
                }, 2000)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const handleOpenSubscribeDialog = () => {
        setShowSubscribeDialog(true)
    }

    const handleCloseSubscribeDialog = () => {
        setShowSubscribeDialog(false)
    }

    const handleLike = async () => {
        if (isProcessingFeedback) return
        setIsProcessingFeedback(true)

        if (isLiked) {
            setIsLiked(false)
            setLikes((prevLikes) => prevLikes.filter((like) => +like?.user_id !== userIdentifier))
        } else {
            setIsLiked(true)
            setIsDisliked(false)
            setLikes((prevLikes) => [
                ...prevLikes,
                { user_id: userIdentifier, decision: 1, object_type: 1 },
            ])
            setDislikes((prevDislikes) =>
                prevDislikes.filter((dislike) => +dislike?.user_id !== userIdentifier),
            )
        }

        try {
            await handlePostUserFeedbackAction(USER_REPO_FEEDBACK[1])
        } catch (error) {
            setIsLiked((prev) => !prev)
            setLikes((prevLikes) => prevLikes.filter((like) => +like?.user_id !== userIdentifier))
        }

        setIsProcessingFeedback(false)
    }

    const handleDislike = async () => {
        if (isProcessingFeedback) return
        setIsProcessingFeedback(true)

        if (isDisliked) {
            setIsDisliked(false)
            setDislikes((prevDislikes) =>
                prevDislikes.filter((dislike) => +dislike?.user_id !== userIdentifier),
            )
        } else {
            setIsDisliked(true)
            setIsLiked(false)
            setDislikes((prevDislikes) => [
                ...prevDislikes,
                { user_id: userIdentifier, decision: 2, object_type: 1 },
            ])
            setLikes((prevLikes) => prevLikes.filter((like) => +like?.user_id !== userIdentifier))
        }

        try {
            await handlePostUserFeedbackAction(USER_REPO_FEEDBACK[2])
        } catch (error) {
            setIsDisliked((prev) => !prev)
            setDislikes((prevDislikes) =>
                prevDislikes.filter((dislike) => +dislike?.user_id !== userIdentifier),
            )
        }

        setIsProcessingFeedback(false)
    }

    const handleEditMode = (value) => {
        if (!value) {
            setEditName(false)
            setEditDescription(false)
            setEditCategory(false)
            reset()
        }
        setEditMode(value)
    }

    const handlePublishChanges = async (data) => {
        await handleUpdateTemplateData(data, templateId, refreshAccessTokenCounter, () => {
            handleEditMode(false)
            reset(data)
        })
        await fetchUserPlaybooks()
    }

    useEffect(() => {
        fetchCaseTypes()
    }, [])

    useEffect(() => {
        if (editName || editDescription || editCategory) handleEditMode(true)
    }, [editDescription, editName, editCategory])

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                showSubscribeDialog &&
                dialogRef.current &&
                !dialogRef.current.contains(event.target)
            ) {
                handleCloseSubscribeDialog()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showSubscribeDialog])

    useEffect(() => {
        if (editDescription) return
        const element = document.querySelector(`.${styles.templateInfoTitleDescription}`)
        if (element?.scrollHeight > element?.clientHeight) {
            setIsOverflowingDescription(true)
        }
    }, [description])

    useEffect(() => {
        if (!userIdentifier) return
        const userHasLiked = likes.some((like) => like.user_id == userIdentifier)
        const userHasDisliked = dislikes.some((dislike) => dislike.user_id == userIdentifier)

        setIsLiked(userHasLiked)
        setIsDisliked(userHasDisliked)
    }, [likes, user, defaultLikes, defaultDislikes, userIdentifier])

    useEffect(() => {
        if (editName) {
            nameInputRef.current?.focus()
        }
    }, [editName])

    useEffect(() => {
        if (editDescription && descriptionInputRef.current) {
            const input = descriptionInputRef.current
            const length = input.value.length
            input.focus()
            input.setSelectionRange(length, length)
        }
    }, [editDescription, descriptionInputRef])

    const ActionButtons = () => {
        return (
            <>
                <Box className={styles.templateInfoTitleFeedback}>
                    <Button
                        onClick={handleLike}
                        className={
                            isLiked
                                ? styles.templateInfoTitleFeedbackLikeActive
                                : styles.templateInfoTitleFeedbackLike
                        }
                    >
                        <img src={isLiked ? likeIconActive : likeIcon} />
                        {likes?.length > 0 ? likes?.length : null}
                    </Button>
                    <Button
                        onClick={handleDislike}
                        className={
                            isDisliked
                                ? styles.templateInfoTitleFeedbackDislikedActive
                                : styles.templateInfoTitleFeedbackDisliked
                        }
                    >
                        <img src={isDisliked ? dislikeIconActive : dislikeIcon} />
                    </Button>
                    <Button
                        ref={buttonRef}
                        onClick={handleShareClick}
                        className={styles.templateInfoTitleFeedbackShare}
                    >
                        <div className={styles.shareIcon}></div>
                    </Button>
                    <CustomPopOver
                        isVisible={isPopoverVisible}
                        text={
                            <div className={styles.templateInfoTitleFeedbackSharePopOver}>
                                <img src={successIcon} alt="success" />
                                Link copied to clipboard
                            </div>
                        }
                        anchor={buttonRef.current}
                    />
                </Box>
            </>
        )
    }

    return (
        <Box className={templateInfoContainerClass}>
            <form className={styles.templateInfoForm} onSubmit={handleSubmit(handlePublishChanges)}>
                <Box className={styles.templateInfoTitle}>
                    <Box className={styles.templateInfoTitleName}>
                        <Controller
                            name={"name"}
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!editName ? (
                                        <>
                                            <span className={styles.templateInfoTitleNameText}>
                                                {name}
                                                {isAllowToEdit && (
                                                    <div
                                                        className={styles.nameEditIcon}
                                                        onClick={handleEditName}
                                                    ></div>
                                                )}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <TextField
                                                {...field}
                                                inputRef={nameInputRef}
                                                disabled={templateDataLoading}
                                                onChange={(value) => {
                                                    field.onChange(value)
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "#6B869A",
                                                    },
                                                }}
                                                error={errors.name}
                                                variant="outlined"
                                                label={"Playbook Name"}
                                                InputProps={{
                                                    className: styles.templateInfoInput,
                                                }}
                                                fullWidth
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        />
                        {!isMobile && !editMode && <ActionButtons />}
                    </Box>
                    {errors?.name && (
                        <span className={styles.templateInfoInputError}>{errors.name.message}</span>
                    )}
                    <Box className={styles.templateInfoSubtitle}>
                        {linkedin_profile ? (
                            <Link
                                to={linkedin_profile}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.templateInfoTitleDesignedLink}
                            >
                                <DesignerInfo displayName={displayName} />
                            </Link>
                        ) : (
                            <DesignerInfo displayName={displayName} />
                        )}
                        <CategoryInfo
                            caseTypes={caseTypes}
                            currentCaseType={templateData?.case_type_id}
                            control={control}
                            isAllowToEdit={isAllowToEdit}
                            editCategory={editCategory}
                            handleEditCategory={handleEditCategory}
                            templateDataLoading={templateDataLoading}
                        />
                    </Box>
                    {isMobile && (
                        <div className={styles.templateInfoTitleMobileButtonsWrapper}>
                            <ActionButtons />
                        </div>
                    )}
                    <Controller
                        name={"description"}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                {!editDescription ? (
                                    <Tooltip
                                        title={isOverflowingDescription ? description : ""}
                                        arrow
                                    >
                                        <Box className={styles.templateInfoTitleDescription}>
                                            {!description ? (
                                                <span>Add playbook description</span>
                                            ) : (
                                                description
                                            )}
                                            {isAllowToEdit && (
                                                <div
                                                    className={styles.nameEditIconDescriprion}
                                                    onClick={handleEditDescription}
                                                />
                                            )}
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <TextField
                                        {...field}
                                        inputRef={descriptionInputRef}
                                        disabled={templateDataLoading}
                                        onChange={(value) => {
                                            field.onChange(value)
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        label={"Description"}
                                        className={styles.templateInfoInputDescription}
                                        InputProps={{
                                            className: styles.templateInfoInput,
                                        }}
                                        fullWidth
                                    />
                                )}
                            </>
                        )}
                    />
                    <>
                        {errors?.description && (
                            <span className={styles.templateInfoInputError}>
                                {errors.description.message}
                            </span>
                        )}
                    </>
                    {tools?.ordered && (
                        <Steps
                            templateData={templateData}
                            selectedStepData={selectedStepData}
                            steps={tools?.ordered}
                            handleSelectStep={handleSelectStep}
                            selectedStepIndex={selectedStepIndex}
                            toolsDim={toolsDim}
                            handleOpenSubscribeDialog={handleOpenSubscribeDialog}
                            control={control}
                            handleEditMode={handleEditMode}
                            editMode={editMode}
                            setValue={setValue}
                            setEditMode={setEditMode}
                            isAllowToEdit={isAllowToEdit}
                            templateDataLoading={templateDataLoading}
                            setToolsIdArray={setToolsIdArray}
                            toolsIdArray={toolsIdArray}
                            toolsConfig={toolsConfig}
                            errors={errors}
                        />
                    )}
                    {!isMobile && (
                        <Box className={styles.templateInfoButtonWrapper}>
                            {editMode ? (
                                <>
                                    <LoadingButton
                                        loading={templateDataLoading}
                                        className={styles.templateInfoButtonSave}
                                        loadingIndicator={
                                            <CircularProgress
                                                style={{ width: "15px", height: "15px" }}
                                            />
                                        }
                                        type="submit"
                                    >
                                        Save Changes
                                        {/* {!templateDataLoading && <img src={buttonSaveChanges} />} */}
                                    </LoadingButton>
                                    <Button
                                        disabled={templateDataLoading}
                                        onClick={() => handleEditMode(false)}
                                        className={styles.templateInfoButtonCancel}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Link
                                        to="https://www.linkedin.com/groups/9508939/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.link}
                                    >
                                        <Button className={styles.templateInfoButtonCommunity}>
                                            Join our community
                                            <div className={styles.joinComunityIcon}></div>
                                            {/* <img src={joinComunityIcon} alt="joinComunityIcon" /> */}
                                        </Button>
                                    </Link>
                                    <Button
                                        onClick={handleOpenSubscribeDialog}
                                        className={styles.templateInfoButtonUpdates}
                                    >
                                        Get Updates
                                        <div className={styles.subscribeIcon}></div>
                                    </Button>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </form>
            <SubscribeDialog
                open={showSubscribeDialog}
                onClose={handleCloseSubscribeDialog}
                forwardedRef={dialogRef}
            />
        </Box>
    )
}
