import React, { useEffect, useState } from "react"
import Notebook from "./Notebook"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSelector, useDispatch } from "react-redux"
import { changeSidebarState } from "../../redux/actions/sidebarActions"
import { changeLoaderState, changeLoaderMessage } from "../../redux/actions/loaderActions"
import { getToolsConfig, getCaseTypesWithFields } from "../../services/standard/standardServices"
import {
    updateCaseInfo,
    addTools,
    getCaseData,
    getEntitiesTypes,
} from "../../services/notebook/notebookServices"
import { connectedIntegrations } from "../../services/integrations/integrationsServices"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import withCustomAuthenticationRequired from "../../components/Hoc/with-custom-authentication-required"
import CaseReportDialog from "./components/CaseReportDialog"
import { noteBookContainerValidationSchema } from "../../utils/validators/notebookContainer"
import { notebookContainerInitialValues } from "../../constants/initialValues"
import { isUserInToolboxPage } from "../../helpers/notebookHelpers"

function NotebookContainer() {
    const { caseId } = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loaderState = useSelector((state) => state.loaderState)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const menuConfig = useSelector((state) => state.menuConfig.value)
    const organizationLogoURL = localStorage.getItem("organizationLogoURL")
    const [caseReportDialogOpen, setCaseReportDialogOpen] = useState(false)
    const [auditTrailPreview, setAuditTrailPreview] = useState("<div/>")
    const [caseTypes, setCaseTypes] = useState([])
    const [entityTypes, setEntitiesTypes] = useState([])
    const [toolsConfig, setToolsConfig] = useState([])
    const [integrations, setIntegrations] = useState([])
    const [sendReportDialogOpen, setSendReportDialogOpen] = useState(false)
    const [successEmailOpen, setSuccessEmailOpen] = useState(false)
    const [failEmailOpen, setFailEmailOpen] = useState(false)
    const [caseTitle, setCaseTitle] = useState(null)
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isFromToolbox, setIsFromToolbox] = useState(false)
    const [loadData, setLoadData] = useState({
        caseTypes: false,
        entityTypes: false,
        toolsMenuConfig: false,
        integrations: false,
        caseData: false,
    })

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
        resetField,
        reset,
        setFocus,
    } = useForm({
        defaultValues: notebookContainerInitialValues,
        resolver: yupResolver(noteBookContainerValidationSchema),
    })
    const values = watch()

    const handleAddTools = async (tools, index_x, index_y, childOf) => {
        const newTools = await addTools(
            caseId,
            tools,
            index_x,
            index_y,
            childOf,
            dispatch,
            refreshAccessTokenCounter,
        )
        resetField("tools")
        setValue("tools", newTools)
        return newTools
    }

    // start with closed sidebar
    useEffect(() => {
        dispatch(changeSidebarState(false))
    }, [])
    // start loader
    useEffect(() => {
        dispatch(changeLoaderState(true))
        dispatch(changeLoaderMessage("Setting up your work station..."))
    }, [])
    // stop loader
    useEffect(() => {
        if (Object.values(loadData).every((element) => element)) {
            dispatch(changeLoaderState(false))
        }
    }, [loadData])

    // case types
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const caseTypesResults = await getCaseTypesWithFields(
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setCaseTypes(caseTypesResults)
                } catch (e) {
                    console.log(e.message)
                } finally {
                    setLoadData((loadData) => ({
                        ...loadData,
                        caseTypes: true,
                    }))
                }
            })()
        }
    }, [isAuthorized])

    // entites
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const entitiesTypesResults = await getEntitiesTypes(
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setEntitiesTypes(entitiesTypesResults)
                } catch (e) {
                    console.log(e.message)
                } finally {
                    setLoadData((loadData) => ({
                        ...loadData,
                        entityTypes: true,
                    }))
                }
            })()
        }
    }, [isAuthorized])

    // tools menu config
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const toolsConfigResults = await getToolsConfig(
                        menuConfig,
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setToolsConfig(toolsConfigResults)
                } catch (e) {
                    console.log(e.message)
                } finally {
                    setLoadData((loadData) => ({
                        ...loadData,
                        toolsMenuConfig: true,
                    }))
                }
            })()
        }
    }, [isAuthorized])

    // integrations
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const currentIntegrations = await connectedIntegrations(
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setIntegrations(currentIntegrations)
                } catch (e) {
                    console.log(e.message)
                } finally {
                    setLoadData((loadData) => ({
                        ...loadData,
                        integrations: true,
                    }))
                }
            })()
        }
    }, [isAuthorized])

    const refreshPageData = async () => {
        try {
            // case data
            const caseData = await getCaseData(caseId, dispatch, refreshAccessTokenCounter)
            if (caseData.title) setCaseTitle(caseData.title)
            if (caseData.urgency === null) {
                caseData.urgency = "Low"
            }
            if (caseData.status === null) {
                caseData.status = "New"
            }
            // "Case status" menu
            if (["New", "In Progress"].includes(caseData.status)) {
                caseData["menu_only_case_status"] = caseData.status
            } else {
                caseData["menu_only_case_status"] = caseData.resolution
            }
            reset(caseData)
        } catch (e) {
            if (e && e.response && e.response.status === 404) navigate("/404")
            console.log(e.message)
        } finally {
            setLoadData((loadData) => ({ ...loadData, caseData: true }))
            setIsDataLoading(false)
        }
    }

    // case data
    useEffect(() => {
        if (isAuthorized) {
            setIsDataLoading(true)
            refreshPageData()
        }
    }, [isAuthorized])

    const updateCaseInfoMap = async (type, value) => {
        try {
            await updateCaseInfo(values.case_id, type, value, dispatch, refreshAccessTokenCounter)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (isAuthorized) {
            if (values.status !== null) {
                updateCaseInfoMap("status", values.status)
            }
        }
    }, [values.status, isAuthorized])

    useEffect(() => {
        if (isUserInToolboxPage(location.pathname)) setIsFromToolbox(true)
    }, [location])

    const setCloseCaseDialogOpenHandler = () => {
        setCaseReportDialogOpen(true)
    }

    return (
        !loaderState && (
            <form onSubmit={handleSubmit(() => {})}>
                {caseReportDialogOpen && (
                    <CaseReportDialog
                        open={caseReportDialogOpen}
                        setClosed={() => setCaseReportDialogOpen(false)}
                        caseTitle={values.title}
                        auditTrailPreview={auditTrailPreview}
                        values={values}
                        control={control}
                        sendReportDialogOpen={sendReportDialogOpen}
                        setSendReportDialogOpen={setSendReportDialogOpen}
                        successEmailOpen={successEmailOpen}
                        setSuccessEmailOpen={setSuccessEmailOpen}
                        failEmailOpen={failEmailOpen}
                        setFailEmailOpen={setFailEmailOpen}
                        setValue={setValue}
                    />
                )}
                <Notebook
                    refreshPageData={refreshPageData}
                    control={control}
                    values={values}
                    setValue={setValue}
                    resetField={resetField}
                    resetAllFields={reset}
                    setCloseCaseDialogOpenHandler={setCloseCaseDialogOpenHandler}
                    auditTrailPreview={auditTrailPreview}
                    watch={watch}
                    sendReportDialogOpen={sendReportDialogOpen}
                    setSendReportDialogOpen={setSendReportDialogOpen}
                    successEmailOpen={successEmailOpen}
                    setSuccessEmailOpen={setSuccessEmailOpen}
                    failEmailOpen={failEmailOpen}
                    setFailEmailOpen={setFailEmailOpen}
                    setFocus={setFocus}
                    handleAddTools={handleAddTools}
                    toolsConfig={toolsConfig}
                    integrations={integrations}
                    caseTypes={caseTypes}
                    entityTypes={entityTypes}
                    updateCaseInfoMap={updateCaseInfoMap}
                    caseTitle={caseTitle}
                    isDataLoading={isDataLoading}
                    isFromToolbox={isFromToolbox}
                />
            </form>
        )
    )
}

export default withCustomAuthenticationRequired(NotebookContainer)
