import React, { useMemo } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Grid from "@mui/material/Grid"
import Tooltip from "@mui/material/Tooltip"
import { useNavigate } from "react-router-dom"
import { CaseReport } from "./CaseReport/CaseReport"
import { PDFViewer, pdf } from "@react-pdf/renderer"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"

export default function CaseReportDialog({ open, setClosed, reportData, displayedReportId }) {
    const navigate = useNavigate()

    const docMemo = useMemo(() => <CaseReport reportData={reportData} />, [reportData])

    const handleDownloadPDF = async () => {
        const asBlob = await pdf(docMemo).toBlob()
        const url = URL.createObjectURL(asBlob)
        const link = document.createElement("a")
        link.href = url
        link.download = `enhanced_due_diligence_report_${displayedReportId}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={setClosed}
                keepMounted
                PaperProps={{
                    style: {
                        background: "rgba(30,30,30,0.65)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                {/* This container now takes full height and hides outer scroll */}
                <DialogContent
                    style={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        height: "100vh",
                        overflow: "hidden",
                    }}
                >
                    {/* Sticky header section stays on top without causing scroll */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            padding: "20px 40px",
                            position: "sticky",
                            top: 0,
                            background:
                                "linear-gradient(180deg, rgba(30,30,30,0.95) 0%, rgba(62,62,62,0) 80.21%)",
                            zIndex: 1000,
                        }}
                    >
                        <Grid item xs={5}>
                            <Button
                                onClick={setClosed}
                                autoFocus
                                color="secondary"
                                style={{
                                    color: "#fff",
                                    fontSize: "1.5em",
                                    textTransform: "none",
                                }}
                            >
                                <ArrowBackIcon
                                    fontSize="inherit"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        marginRight: "15px",
                                    }}
                                />
                                Enhanced Due Diligence Report
                            </Button>
                        </Grid>
                        <Grid item container xs={7} justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <Button
                                    onClick={handleDownloadPDF}
                                    autoFocus
                                    color="secondary"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        textTransform: "none",
                                        padding: "6px 8px 0 8px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Tooltip title="Download Report">
                                        <PictureAsPdfIcon
                                            style={{
                                                color: "#fff",
                                                fontSize: "1.5em",
                                            }}
                                        />
                                    </Tooltip>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{
                            flexGrow: 1,
                            width: "50vw", // Reduced width as needed
                            margin: "0 auto", // Removed horizontal centering
                            padding: 0, // No horizontal padding
                        }}
                    >
                        <PDFViewer
                            style={{
                                width: "100%",
                                height: "100%",
                                border: "none",
                                margin: 0,
                                padding: 0,
                            }}
                            showToolbar={false}
                        >
                            {docMemo}
                        </PDFViewer>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}
